import { createContext, useState, useEffect, useCallback, useContext } from "react";
import jwt_decode from "jwt-decode";
import settings from "../settings";
import { FullScreenLoading } from "../components/Layouts";
import { t } from 'ttag'
import { useModelRequest } from "../utils/APIMethods";
import LayoutContext from "./LayoutContext";
import useAxios from "../utils/useAxios";
import { ModelContextProvider } from "./_common";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const {message, setLanguage}=useContext(LayoutContext)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [authTokens, setAuthTokens] = useState(localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null)

    useEffect(()=>{
        if(authTokens){
            localStorage.setItem("authTokens",JSON.stringify(authTokens))
            setUser(jwt_decode(authTokens.access))
            // console.log('token saved',authTokens)
        }
    }, [authTokens])

    const [user, setUser] = useState(() =>authTokens
        ? jwt_decode(authTokens.access)
        : null
    );

    // LOGOUT

    const logoutUser = useCallback(() => {
        localStorage.removeItem('authTokens');
        localStorage.removeItem('current_workspace');
        localStorage.removeItem('lastProject')
        setAuthTokens(null)
        setUser(null);
        setDataLoaded(false)
    },[])

    // LOGIN

    const api = useAxios({setUser,logoutUser, authTokens, setAuthTokens})
    const modelRequest = useModelRequest({setUser,logoutUser, authTokens, setAuthTokens})

    const loginWithToken = useCallback(async (data) => {
        setUser(jwt_decode(data.access));
        setAuthTokens(data);
        return
    }, [])

    const oauthLogin = useCallback(async(code, provider, user)=>(
        
        api.get(`${settings.api.url}oauth/login/${provider}/`,{params:{code,user}})
        .then(r=> loginWithToken(r.data))
        .catch(e=>{message.error(e.response.data.error?e.response.data.error:e.response.data)})
    ), [loginWithToken,api,message])
    
    const loginUser = useCallback(async (username, password) => (
        modelRequest('user', 'POST', {username, password}, null, 'login')
        .then(r=>{
            if (r.data.access){
                loginWithToken(r.data)
                return ({loggedIn:true})
            }
            return r.data
        })
    ),[modelRequest, loginWithToken])

    // OTP

    const sendEmailOTP = useCallback(async()=>(
        api.post('otp/send/')
        .then(r=>{message.success(t`Code sended`);return r})
        .catch(e=>{message.error(t`Error while sending code`,typeof(e.response.data)==='string'?e.response.data:'');throw e})
    ),[api, message])

    const checkEmailOTP = useCallback(async(code)=>api.post('otp/check/',{code}), [api])
    
    const resendCode = useCallback(async (ephemeral_token) => (
        api.post(`${settings.api.url}otp/send/`, {ephemeral_token})
        .then(r=>{return r})
        // FIXME unnecessary message
        .catch(e=>{message.error(t`Error while sending code`);throw e})
    ),[api, message])

    const confirmVerification = useCallback(async(ephemeral_token, code, initial) => {
        return api.post('otp/check/',{ephemeral_token,code})
        .then(r=>{
            loginWithToken(r.data)
            return r.data
        })
    },[api, loginWithToken])

    // REGISTRATION

    const registerUser = useCallback(async (req_data) => (
        modelRequest('user','POST', req_data, null, 'register')
        .then(r=>{return r.data})
    ),[modelRequest])

    // RESET PASS

    const resetPass = useCallback(async (email) => (
        api.post(`${settings.api.url}users/password_reset/`,{email})
        .then(r=>{return r.data})
    ),[api])

    const validateResetToken = useCallback(async (token, email) => (
        api.post(`${settings.api.url}users/password_reset/validate_token/`,{token,email})
        .then(r=>{return r.data})
    ),[api])

    const confirmResetPass = useCallback(async (token, email, password) => (
        api.post(`${settings.api.url}users/password_reset/confirm/`,{token, email, password})
        .then(r=>{return r.data})
    ),[api])

    const contextData = {
        authTokens,
        setAuthTokens,
        user,
        setUser,
        logoutUser,
        oauthLogin,
        loginUser,
        loginWithToken,
        sendEmailOTP,
        checkEmailOTP,
        resendCode,
        confirmVerification,
        registerUser,
        resetPass,
        validateResetToken,
        confirmResetPass
    }

    const onDataLoad = useCallback((data)=>{
        // console.log('data loaded')
        setDataLoaded(true)
        setLanguage(data.language)
    },[setLanguage])

    return <ModelContextProvider
        model='user'
        id={user?.user_id}
        ignoreEmpty={!user}
        loadFromPatch
        Context={AuthContext}
        contextData={contextData}
        loadingMessage={t`Loading user data...`}
        modelLabel = {t`User`}
        modelRequest={modelRequest}
        onDataLoad={onDataLoad}
        silent
    >
        {user
            ?dataLoaded
                ? children
                : <FullScreenLoading label={t`Authorization...`}/>
            : children
        }
    </ModelContextProvider>
}
