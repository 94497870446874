import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import settings from "../settings"
import { LabeledLoading } from "../components/Layouts"
import {t} from 'ttag' 
import { Skeleton } from "primereact/skeleton"
import useAxios from "./useAxios"
// import StackTrace from 'stacktrace-js';

const url = settings.api.url

export const modelRequest = async (api, model, method="GET", data={}, id, action='') => {
    const modelname = model+'s'
    if(action?.endsWith('/')){action=action.slice(0, -1)}
    let sp
    if(method.toLowerCase()==='get' && data && Object.keys(data).length!==0){
        sp = new URLSearchParams(data).toString()
    }
    const modelUrl=`${url}${modelname}/${id?id+'/':''}${action?action+'/':''}${sp?"?"+sp:''}`
    let requestParams = {
        url:modelUrl,
        method,
        data
    }
    return api.request(requestParams)

}

export const useModelRequest = (props) => {
    const api=useAxios(props)

    const request = useCallback(async(model, method="GET", data={}, id, action='')=>{
        const modelname = model+'s'
        if(action?.endsWith('/')){action=action.slice(0, -1)}
        let sp
        if(method.toLowerCase()==='get' && data && Object.keys(data).length!==0){
            sp = new URLSearchParams(data).toString()
        }
        const modelUrl=`${url}${modelname}/${id?id+'/':''}${action?action+'/':''}${sp?"?"+sp:''}`
        let requestParams = {
            url:modelUrl,
            method,
            data
        }
        // console.log('model request', method, modelUrl, data)
        // const makeRequest = async(stackframes)=> api.request(requestParams)
        //     .then(r=>{
        //         console.log(
        //             `%c${method.toUpperCase()}`,'color:orange',
        //             modelUrl,
        //             r,
        //             'Trace:\n'+
        //             stackframes.map(f=>`    ${f.fileName} :${f.lineNumber}`).join('\n')
        //         )
        //         return r
        //     })
        // return StackTrace.get().then(makeRequest)
        return api.request(requestParams)
            .then(r=>{
                settings.debug && console.log(`%c${method.toUpperCase()}`, 'color:orange', modelUrl,r)
                return r
            })
    },[api])

    return request
}

export const LoadFromAPI = forwardRef(({model, id, reqData, action='', children, loadingLabel, skeleton=false, skeletonProps},ref)  => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const modelRequest=useModelRequest()

    const getData = useCallback(async(viewLoading=true)=>{
        if(viewLoading){setLoading(true)}
        return modelRequest(model, 'GET', reqData, id, action)
            .then(r=>{
                setData(r.data)
                setLoading(false)
            })
            .catch(e=>{
                setLoading(false)
                setError(t`Error while loading data`)
                throw e
            })
        },[model, id, action, reqData, modelRequest])

    useEffect(()=>{
        getData()
    },[getData])

    useImperativeHandle(ref,()=>({data,getData}),[data,getData])

    return loading
        ? skeleton
            ? <Skeleton {...skeletonProps}/>
            : <LabeledLoading label={loadingLabel||t`Loading...`}></LabeledLoading>
        : error
            ? error
            : typeof(children)=='function'
                ? children({data, getData})
                : children
})