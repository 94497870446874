import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useCallback, useContext, useMemo } from "react";
import AuthContext from "../context/AuthContext";
import settings from "../settings";
import LayoutContext from "../context/LayoutContext";
import {t} from 'ttag'

const baseURL = settings.api.url;

const tokenIsExpired = token => {
    const user = jwt_decode(token);
    const res = dayjs.unix(user.exp).diff(dayjs()) < 1;
    return res
}

const useAxios = ({setUser, logoutUser, authTokens, setAuthTokens, files}={}) => {
    const authContext = useContext(AuthContext);
    const {language,message, setError} = useContext(LayoutContext)

    setUser = useMemo(()=>setUser || authContext?.setUser, [setUser, authContext?.setUser])
    logoutUser = useMemo(()=>logoutUser || authContext?.logoutUser, [logoutUser, authContext?.logoutUser])
    setAuthTokens = useMemo(()=>setAuthTokens || authContext?.setAuthTokens, [setAuthTokens, authContext?.setAuthTokens])
    authTokens = useMemo(()=>authTokens || authContext?.authTokens, [authTokens, authContext?.authTokens])
    
    const maxRetries = 10

    const axiosConfig = useMemo(()=>{
        let conf = {
            baseURL,
            headers: {
                'Content-Type': files?'multipart/form-data':'application/json',
                "Accept-Language":language
            }
        }
        if(authTokens){
            conf.headers.Authorization=`Bearer ${authTokens.access}`
        }
        return conf
    },[files,language,authTokens])


    const axiosInstance = useMemo(()=>axios.create(axiosConfig),[axiosConfig]);

    const checkToken = useCallback(async (req, retries=0, skipHeaderCheck=false) => {
        if(retries>maxRetries){
            logoutUser()
            console.error('Token expired or not valid, logging out')
            return
        }
        if(!req.headers.Authorization || !authTokens){return req}
        const reqToken = req.headers.Authorization.replace('Bearer ','')
        const storedTokens = JSON.parse(localStorage.getItem('authTokens'))
        if(!skipHeaderCheck){
            const isExpired = tokenIsExpired(reqToken)
            if (!isExpired) {return req}
            if (storedTokens.access !== reqToken){
                req.headers.Authorization = `Bearer ${storedTokens.access}`
                return checkToken(req, retries+1)
            }
        }
        const stillExpired = tokenIsExpired(storedTokens.access)
        if (!stillExpired) {
            settings.debug && console.log('Token refreshed')
            req.headers.Authorization = `Bearer ${storedTokens.access}`
            return req
        }
        settings.debug && console.log('Token expired')
        const refreshIsExpired = tokenIsExpired(storedTokens.refresh)
        console.log({refreshIsExpired, storedTokens})
        if (refreshIsExpired){
            console.log("Refresh tocken expired, logging out.")
            return logoutUser()
        }
        return axios
            .post(`${baseURL}token/refresh/`, {refresh: storedTokens.refresh})
            .then(response => {
                setAuthTokens(response.data);
                setUser(jwt_decode(response.data.access));
                req.headers.Authorization = `Bearer ${response.data.access}`;
                settings.debug && console.log('Token refreshed')
                return req;
            })
            .catch(async error=>{
                if(error.response?.status===401 && error.response?.data.code==="token_not_valid"){
                    console.warn('Token expired, trying get new', retries)
                    await new Promise(r => setTimeout(r, retries*100));
                    return checkToken(req, retries+1)
                }
                setError(t`Something wrong. Try later.`)
                throw error
            })
    },[logoutUser, setUser, setError, setAuthTokens, authTokens])

    const getAxios = useCallback(()=>{
        axiosInstance.interceptors.request.use(req=>{
            if(files&&!(req.data instanceof FormData)){
            let formData = new FormData()
            Object.keys(req.data).forEach(key=>{
                formData.append(key, req.data[key])
            })
            req.data = formData
            }
            return checkToken(req)
        });

        axiosInstance.interceptors.response.use(
            resp=>{
                // console.log(`${id} %c ${resp.config.method} ${resp.config.url} ${resp.status}`, 'color:orange', resp)
                if(resp.data.detail){
                    message.info(resp.data.detail)
                }
                if(resp.data.message){
                    message.success(resp.data.message)
                }
                return resp
            },
            error => {
                if([408, 504].includes(error.response?.status)){
                    message.error(t`Request taking too long`, t`Check your Internet connection.`)
                }
                else if(error.response?.status===401 && error.response?.data.code==="token_not_valid"){
                    console.warn('Token expired, trying get new', 0, error)
                    return checkToken(error.config, 0, true)
                        .then(req=>{return axiosInstance(req)})
                }
                else if (error.response?.status===401){
                    return logoutUser()
                }
                else if([502, 503].includes(error.response?.status) || error.code==="ERR_NETWORK"){
                    message.error(t`Server Unavailable`, t`Check your Internet connection.`)
                    setError(t`Server Unavailable. Check your Internet connection or try later.`)
                }
                else if(error.response.data.detail){
                    message.error(error.response.data.detail)
                }
                else if (typeof(error.response.data)=='string') {
                    message.error(t`Error`, error.message)
                }
                console.error(error.config?.method, error.config?.url, error.response?.status, error)
                throw error
            }
        )
        return axiosInstance;
    }, [axiosInstance, checkToken, files, message, setError, logoutUser])

    return getAxios()
};

export default useAxios;