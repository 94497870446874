const settings = {
    host: process.env.REACT_APP_FRONTEND_HOST,
    maintenance: process.env.REACT_APP_MAINTENANCE.toLowerCase()==='true',
    debug: process.env.REACT_APP_DEBUG.toLowerCase()==='true',
    otp_timeout: parseInt(process.env.REACT_APP_OTP_TIMEOUT||30),
    api:{
        // url:"http://192.168.1.143:8080/api/"
        url: process.env.REACT_APP_BACKEND_URL,
        backendHost: process.env.REACT_APP_BACKEND_HOST
    },
    oauth:{
        url: process.env.REACT_APP_OAUTH_URL
    }
}

export default settings